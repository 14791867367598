


































import { upgradeAndroid } from '@/api/common'
import { Component, Vue } from 'vue-property-decorator'
@Component
export default class DownLoad extends Vue {
  loading: any = false
  showTip: any = false
  async downloadApp() {
    if (this.isWeiXinLogin()) {
      this.showTip = true
      return
    } else {
      this.loading = true
      const [error, data] = await upgradeAndroid()
      if (error) {
        this.loading = false
        return
      } else {
        this.loading = false
        window.open(data.downloadUrl)
        //plus.runtime.openURL(data.downloadUrl)
      }
    }
  }
  //判断是否微信打开
  isWeiXinLogin():boolean {
    console.log('jinru')
    const ua: any = window.navigator.userAgent.toLowerCase()
    console.log(ua) //mozilla/5.0 (iphone; cpu iphone os 9_1 like mac os x) applewebkit/601.1.46 (khtml, like gecko)version/9.0 mobile/13b143 safari/601.1
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      return true
    } else {
      return false
    }
  }
}
